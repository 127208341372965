import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

import "./App.scss";

import * as Actions from "./redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { routes } from "./imports/config";

import ReactJoyride from "react-joyride";
import { BCODE_ADDRESS } from "./imports/contract_config";
import { finishTutorial } from "./redux/actions";

const TOUR_STEPS = [
    {
        target: ".button-add",
        content:
            "Utilizza questo pulsante per aggiungere una nuova prenotazione",
        disableBeacon: true,
    },
    {
        target: ".store-wrap",
        content: "Selezione il negozio che preferisci",
        disableBeacon: true,
    },
    {
        target: ".slots",
        content: "Seleziona lo slot di tempo",
        disableBeacon: true,
    },
    {
        target: ".step-4",
        content: "Conferma per prenotare",
        disableBeacon: true,
    },
    {
        target: ".reservation",
        content: "Ecco qui la tua prenotazione",
        disableBeacon: true,
    },
];

const CustomRoute = ({
    component: Component,
    tutorial,
    nextStep,
    prevStep,
    finishTutorial,
    stopTutorial,
    ...props
}) => {
    // const [tourState, setStepIndex] = useReducer(reducer, {
    //   key: new Date(), // This field makes the tour to re-render when we restart the tour
    //   run: true,
    //   continuous: true, // Show next button
    //   loading: false,
    //   stepIndex: 0, // Make the component controlled
    //   steps: TOUR_STEPS,
    //   callback: callback,
    // });

    // console.log(props);

    const callback = data => {
        const { action, index, type, status } = data;

        switch (action) {
            // case action === "start":
            //     break;
            case "next":
                switch (index) {
                    case 0:
                        props.history.push("/store");
                        break;
                    case 1:
                        props.history.push(`/store/${BCODE_ADDRESS}`);
                        break;
                    case 2:
                        props.history.push(`/store/${BCODE_ADDRESS}/tutorial`);
                        break;
                    case 3:
                        props.history.push("/");
                        break;
                    case 4:
                        finishTutorial();
                        break;
                    default:
                        stopTutorial();
                        props.history.push("/");
                        break;
                }

                nextStep();

                break;
            case "prev":
                switch (index) {
                    case 1:
                        props.history.push("/dashboard");
                        break;
                    case 2:
                        props.history.push(`/store/${BCODE_ADDRESS}`);
                        break;
                }

                prevStep();
                break;
            case "close":
                stopTutorial();
                finishTutorial();
                // props.history.push("/");
                break;
        }
    };

    return (
        <div className="inner-container">
            <Component {...props} />
            <ReactJoyride
                // {...tourState}
                locale={{
                    last: "Fine",
                    skip: "Salta",
                    next: "Avanti",
                    back: "Indietro",
                }}
                run={tutorial.run}
                steps={TOUR_STEPS}
                continuous
                callback={callback}
                stepIndex={tutorial.stepIndex}
                styles={{
                    options: {
                        arrowColor: "#fff",
                        backgroundColor: "#fff",
                        overlayColor: "rgba(0, 0, 0, 0.4)",
                        textColor: "#1d1d1b",
                        width: 700,
                        zIndex: 1000,
                    },
                    buttonNext: { backgroundColor: "#b8d04e" },
                    buttonBack: {
                        color: "#1d1d1b",
                    },
                }}
            />
        </div>
    );
};

function App(props) {
    const { tutorial, nextStep, prevStep, stopTutorial, finishTutorial } =
        props;

    return (
        <div className="App">
            <Switch>
                {routes.map((route, i) => {
                    return !route.private ? (
                        <Route
                            exact
                            key={i + route.path}
                            path={route.path}
                            component={props => (
                                <CustomRoute
                                    {...props}
                                    component={route.component}
                                    tutorial={tutorial}
                                    nextStep={nextStep}
                                    prevStep={prevStep}
                                    stopTutorial={stopTutorial}
                                />
                            )}
                        />
                    ) : props.isAuth ? (
                        <Route
                            exact
                            key={route.path + i}
                            path={route.path}
                            component={props => (
                                <CustomRoute
                                    {...props}
                                    component={route.component}
                                    tutorial={tutorial}
                                    nextStep={nextStep}
                                    prevStep={prevStep}
                                    stopTutorial={stopTutorial}
                                    finishTutorial={finishTutorial}
                                />
                            )}
                        />
                    ) : (
                        <Redirect key={"redirect"} to="/" />
                    );
                })}
            </Switch>
        </div>
    );
}

function mapStateToProps({ general, tutorial }) {
    // console.log("APP =>", tutorial);
    return {
        isAuth: general.isAuth,
        tutorial,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
