import moment from "moment";
import bs58 from "bs58";
import { ethers } from "ethers";
import { void_ipfs_addr } from "./contract_config";

export function generateGoogleCalendarLink(slot) {
    // console.log(
    //     "RESERVATION ==>",
    //     moment.unix(slot.begin).format("YYYYMMDDTHHmmssZ"),
    // );
    return `https://www.google.com/calendar/render?action=TEMPLATE&text=Prenotazione+da+${
        slot.shopName
    }&location=${"PROVA"}&dates=${moment
        .unix(slot.begin)
        .format("YYYYMMDDTHHmmss")}%2F${moment
        .unix(slot.end)
        .format("YYYYMMDDTHHmmss")}`;
}

export async function getCachedData(ipfsNode, hexString) {
    // Create Abi Coder instance
    const abiCoder = ethers.utils.defaultAbiCoder;

    let buf = Buffer.from(hexString.slice(2), "hex");
    let cid = bs58.encode(buf).toString();

    console.log("CID IS VOID ==>", cid === void_ipfs_addr);
    if (cid !== void_ipfs_addr) {
        try {
            const chunks = [];

            for await (const chunk of ipfsNode.cat(cid)) {
                chunks.push(chunk);
            }

            console.log(chunks);
            const abiDecoded = abiCoder.decode(
                ["uint256[]"],
                "0x" + chunks[0].toString("hex"),
            );

            return abiDecoded[0].map(el => el.toString());
        } catch (err) {
            console.log(err);
            return null;
        }
    } else {
        return [];
    }
}

export const delay = ms =>
    new Promise((resolve, reject) => setTimeout(resolve, ms));
