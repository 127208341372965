import React from "react";
import { Link } from "react-router-dom";

import "./customButton.scss";

//TODO: CONFIGURE ICON
export default function CustomButton(props) {
    const {
        onClick,
        className,
        label,
        externalLink,
        internalLink,
        icon,
    } = props;

    return (
        <>
            {externalLink ? (
                <a className={`button ${className}`} href={externalLink}>
                    {label}
                </a>
            ) : internalLink ? (
                <Link to={internalLink} className={`button ${className}`}>
                    {label}
                </Link>
            ) : (
                <div className={`button ${className}`} onClick={onClick}>
                    {label}
                </div>
            )}
        </>
    );
}
