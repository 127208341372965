import Booking from "../pages/Booking";
import Dashboard from "../pages/Dashboard";
import ManageReservation from "../pages/ManageReservation";
import Slot from "../pages/Slot";
import Test from "../pages/Test";
import Login from "../pages/Login";

export const routes = [
  {
    path: "/",
    component: Login,
    title: "ACCESSO/COLLEGAMENTO",
    private: false,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    title: "DASHBOARD",
    private: true,
  },
  { path: "/store", component: Booking, title: "BOOKING", private: true },
  { path: "/store/:store", component: Slot, title: "BOOKING", private: true },
  {
    path: "/store/:store/:slot",
    component: ManageReservation,
    title: "PRENOTAZIONE",
    private: true,
  },
  {
    path: "/reservation/:id",
    component: ManageReservation,
    title: "GESTIONE PRENOTAZIONE",
    private: true,
  },
  { path: "/test", component: Test, title: "TEST", private: false },
];
