import React, { Component } from "react";
import { Link } from "react-router-dom";

import Calendar from "react-calendar-material";
import moment from "moment";
import { groupBy, isEmpty, isEqual } from "lodash";

import spinner from "../assets/knobs.png";

import AppBar from "../components/AppBar";

import TimeButton from "../components/TimeButton";

import * as Actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { COLORS, LOAD_STATUS } from "../imports/constants";
import { getContract } from "../imports/blockchain_utilities";
import { BCODE_ADDRESS } from "../imports/contract_config";

var transfersListener, filterFromMe;

class Slot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shop: this.props.shops?.find(
                shop => shop.id === this.props.match.params.store,
            ),
            shopId: this.props.match.params.store,
            currentDate: moment(),
            selectSlot: null,
            slots: [],
        };

        this.setDateFilter = this.setDateFilter.bind(this);
        this.changeMonth = this.changeMonth.bind(this);
    }

    changeMonth = i => {
        this.setState({ currentDate: this.state.currentDate.add(i, "month") });
    };

    setDateFilter = value => {
        this.setState({
            currentDate: moment(value),
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.wallet) {
            this.props.loadDataRequest();
        }
        if (
            !isEqual(prevProps.slots, this.props.slots) ||
            !moment(prevState.currentDate).isSame(this.state.currentDate)
        ) {
            this.setState({
                slots: Object.values(
                    groupBy(
                        this.props.slots[this.state.shopId]?.filter(slot => {
                            return moment
                                .unix(slot.begin)
                                .isSame(this.state.currentDate, "day");
                        }),
                        "begin",
                    ),
                ),
            });
        }
    }

    async componentDidMount() {
        if (!this.props.shops?.find(shop => shop.id === this.state.shopId)) {
            await this.props.addShopRequest({
                id: this.state.shopId,
            });
        }
        if (!this.props.tutorial.run) {
            this.props.getSlotsRequest({ shopId: this.state.shopId });

            //Event listner to handle real time booking from other user
            let contract = getContract(this.props.wallet, this.state.shopId);

            // filterFromMe = contract.filters.Transfer(await contract.owner());

            // transfersListener = contract.on(
            //     filterFromMe,
            //     async (fromAddress, toAddress, value, event) => {
            //         console.log("ON");
            //         this.props.removeToken({
            //             tokenId: await event.args.tokenId.toString(),
            //             shopId: this.state.shopId,
            //         });
            //     },
            // );

            this.setState({
                slots: Object.values(
                    groupBy(
                        this.props.slots[this.props.match.params.store]?.filter(
                            slot => {
                                return moment
                                    .unix(slot.begin)
                                    .isSame(this.state.currentDate, "day");
                            },
                        ),
                        "begin",
                    ),
                ),
            });
        } else {
            console.log("Tutorial is running...");
            this.setState({
                slots: [
                    [
                        {
                            tokenId: "tutorial",
                            begin: moment().unix(),
                            end: moment().unix(),
                            index: 0,
                            shopId: BCODE_ADDRESS,
                        },
                    ],
                ],
            });
        }
    }

    componentWillUnmount() {
        // console.log(transfersListener);
        if (!this.props.tutorial.run && transfersListener) {
            transfersListener.removeListener(filterFromMe, () => {
                console.log("not listening anymore");
            });
        }
    }

    render() {
        return (
            <div className="page">
                <AppBar pageName="BOOKING" back={this.props.history.goBack} />

                <p className="shop-name">{this.state.shop?.name}</p>

                <Calendar
                    accentColor={COLORS.secondary}
                    orientation={"flex-col"}
                    border={true}
                    showHeader={false}
                    onDatePicked={d => {
                        this.setDateFilter(d);
                    }}
                    onChangeMonth={this.changeMonth}
                    lang="ita"
                    style={{ flex: "2" }}
                    eventsBool={[
                        ...Array(this.state.currentDate.daysInMonth()).keys(),
                    ].map(day =>
                        this.props.slots[this.props.match.params.store]?.filter(
                            slot => {
                                return (
                                    moment
                                        .unix(slot.begin)
                                        .isSame(
                                            this.state.currentDate,
                                            "month",
                                        ) &&
                                    moment.unix(slot.begin).date() - 1 === day
                                );
                            },
                        ).length
                            ? true
                            : false,
                    )}
                />

                <div className="slots">
                    {this.state.slots?.length ? (
                        <div className="slots-container">
                            {this.state.slots.map((slot, i) => (
                                <div
                                    key={slot[0].tokenId}
                                    className="slot-wrapper"
                                >
                                    <div
                                        className={`inner ${
                                            moment
                                                .unix(slot[0].begin)
                                                .isBefore(moment())
                                                ? "no-transition"
                                                : ""
                                        }`}
                                    >
                                        {/* Front face */}
                                        <TimeButton
                                            key={i}
                                            begin={slot[0].begin}
                                            end={slot[0].end}
                                            tokenId={slot[0].tokenId}
                                            total={slot.length}
                                        />
                                        {/* Back face */}
                                        <Link
                                            key={slot[0].tokenId}
                                            to={`/store/${this.state.shopId}/${slot[0].tokenId}`}
                                            className="time-button back"
                                        >
                                            {slot.length}
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : this.props.status === LOAD_STATUS.PENDING ? (
                        <div className="fade slot">
                            <div className="spinner-wrap slot">
                                <img src={spinner} className="spinner " />
                            </div>
                        </div>
                    ) : (
                        <div className="no-slots">
                            <span>Non ci sono slot disponibili</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps({ general, tutorial }) {
    return {
        wallet: general.wallet,
        slots: general.slots,
        mnemonic: general.mnemonic,
        status: general.slotsStatus,
        tutorial,
        shops: [...general.shops, ...general.untrustedShops],
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Slot);
