import { walletConstants } from "../constants/wallet.constants";
import {
    LOAD_STATUS,
    RESERVATION_STATUS,
    RESET_ERROR,
    SHOP_STATUS,
} from "../../imports/constants";

import { produce } from "immer";
import { BCODE_ADDRESS } from "../../imports/contract_config";

const initialState = {
    slots: {},
    reservations: [],
    shops: [],
    untrustedShops: [],
    mnemonic: "",
    wallet: null,
    registry: [[], []],
    created: null,
    error: false,
    confirmed: null,
    canceled: null,
    status: null,
    userBalance: 0,
    isAuth: false,
    slotsStatus: null,
    loading: false,
};

export default (state = initialState, action) => {
    return produce(state, draftState => {
        switch (action.type) {
            case walletConstants.LOAD_DATA_REQUEST:
            case walletConstants.UPDATE_DATA_REQUEST:
            case walletConstants.CREATE_WALLET_REQUEST:
            case walletConstants.GET_RESERVATIONS_REQUEST:
            case walletConstants.CONNECT_WALLET_REQUEST:
            case walletConstants.GET_SHOPS_REQUEST:
            case walletConstants.BOOK_SLOT_REQUEST:
            case walletConstants.DELETE_RESERVATION_REQUEST:
                draftState.loading = true;
                draftState.status = RESERVATION_STATUS.PENDING;
                draftState.error = null;
                break;

            case walletConstants.UPDATE_DATA:
                draftState.registry = action.payload.registry;
                draftState.userBalance = action.payload.balance;
                break;

            case walletConstants.GET_SLOTS_REQUEST:
                draftState.slotsStatus = RESERVATION_STATUS.PENDING;
                break;

            case walletConstants.REMOVE_TOKEN:
                draftState.slots[action.payload.shopId] = draftState.slots[
                    action.payload.shopId
                ].filter(slot => slot.tokenId !== action.payload.tokenId);
                break;

            case walletConstants.CREATE_WALLET:
                draftState.wallet = action.payload.wallet;
                draftState.mnemonic = action.payload.wallet.mnemonic.phrase;
                draftState.created = true;
                draftState.isAuth = true;
                draftState.status = LOAD_STATUS.SUCCESS;
                break;

            case walletConstants.CONNECT_WALLET:
                draftState.wallet = action.payload.wallet;
                draftState.mnemonic = action.payload.mnemonic;
                draftState.created = false;
                draftState.isAuth = true;
                break;

            case walletConstants.LOAD_DATA:
                draftState.wallet = action.payload.wallet;
                draftState.status = LOAD_STATUS.SUCCESS;
                draftState.error = RESET_ERROR;
                break;

            case walletConstants.LOAD_SUCCESS:
                draftState.loading = false;
                draftState.status = LOAD_STATUS.SUCCESS;
                draftState.slotsStatus = LOAD_STATUS.SUCCESS;
                draftState.error = RESET_ERROR;
                break;

            case walletConstants.LOAD_FAILURE:
                draftState.loading = false;
                draftState.status = RESERVATION_STATUS.ERROR;
                break;

            case walletConstants.LOAD_PENDING:
                action.payload
                    ? (draftState.slotsStatus = RESERVATION_STATUS.PENDING)
                    : (draftState.status = RESERVATION_STATUS.PENDING);
                break;

            case walletConstants.GET_RESERVATIONS:
                draftState.reservations = action.payload;
                break;

            case walletConstants.ADD_SHOP:
                draftState.untrustedShops = action.payload;
                draftState.confirmed = SHOP_STATUS.ADDED;

                break;

            case walletConstants.GET_USER_BALANCE:
                draftState.userBalance = action.payload.balance;
                draftState.registry = action.payload.registry;
                break;

            case walletConstants.GET_SHOPS:
                draftState.shops = action.payload.shops;
                draftState.status = RESERVATION_STATUS.SUCCESS;
                break;
            case walletConstants.GET_SLOTS:
                draftState.slots[action.payload.shopId] = action.payload.slots;
                draftState.slotsStatus = RESERVATION_STATUS.SUCCESS;
                break;

            case walletConstants.BOOK_SLOT:
                draftState.slots[action.payload.shopId] = action.payload.slots;
                draftState.status = RESERVATION_STATUS.CONFIRMED;
                draftState.confirmed = RESERVATION_STATUS.CONFIRMED;
                draftState.loading = false;
                break;

            case walletConstants.DELETE_RESERVATION:
                draftState.reservations = action.payload.reservations;
                if (!action.payload.old) {
                    draftState.canceled = RESERVATION_STATUS.CANCELED;
                    draftState.status = RESERVATION_STATUS.CANCELED;
                }

                break;

            case walletConstants.RESET_ERROR:
                draftState.status = RESET_ERROR;
                draftState.error = false;
                break;

            case walletConstants.DISMISS:
                draftState.confirmed = 0;
                draftState.canceled = 0;
                break;

            case walletConstants.RESET_CREATE:
                draftState.created = false;
                break;

            case walletConstants.ERROR:
                draftState.error = action.payload;
                break;

            case walletConstants.SUCCESS:
                draftState.status = LOAD_STATUS.SUCCESS;
                draftState.slotsStatus = LOAD_STATUS.SUCCESS;
                break;

            case walletConstants.CLEAR_STORAGE:
                return initialState;

            default:
                return draftState;
        }
    });
};
