import { tutorialConstants } from "../constants/tutorial.constants";

const initialState = {
    run: false,
    stepIndex: 0,
    status: false,
    error: null,
    firstTime: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case tutorialConstants.NEXT_STEP: {
            return {
                ...state,
                // run: true,
                stepIndex: state.stepIndex + 1,
            };
        }
        case tutorialConstants.PREV_STEP: {
            return {
                ...state,
                // run: true,
                stepIndex: state.stepIndex - 1,
            };
        }
        case tutorialConstants.RESET: {
            return {
                ...initialState,
                stepIndex: 0,
                run: false,
            };
        }
        case tutorialConstants.START: {
            return {
                ...state,
                run: true,
            };
        }
        case tutorialConstants.STOP: {
            return {
                ...state,
                run: false,
            };
        }

        case tutorialConstants.NEXT_STOP: {
            return {
                ...state,
                run: false,
                stepIndex: state.stepIndex + 1,
            };
        }

        case tutorialConstants.FINISH: {
            return { ...state, run: false, firstTime: false };
        }

        default:
            return state;
    }
};
