import React from "react";

import moment from "moment";
import placeholder from "../assets/imgplaceholder.png";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

export default function Store(props) {
    return (
        <div className="store">
            {!props.shop.untrusted && (
                <VerifiedUserIcon className={"verified-check"} />
            )}
            <p className="shopname">{props.shop.name}</p>
            <div className="div1">
                <p className="address">
                    {props.shop.address
                        ? props.shop.address
                        : "Nessun indirizzo"}
                </p>

                <p className="phone">
                    {"Tel. "}
                    {props.shop.phone ? props.shop.phone : "Nessun telefono"}
                </p>
            </div>
            {/* <div className="data">
        {props.begin ? (
          <p>
            {moment(props.begin).format("DD/MM/YYYY HH:mm")} -{" "}
            {moment(props.end).format("HH:mm")}
          </p>
        ) : (
          ""
        )}
      </div> */}
            <img className="image" src={props.shop.img || placeholder} />
        </div>
    );
}
