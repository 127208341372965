import { createTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import colors from "./styles/_colors.scss";

const theme = createTheme({
    palette: {
        // primary: { main: "#3D656B", light: "#6A9399", dark: "#103B40" },
        // secondary: { main: "#B24A2B", light: "#E97955", dark: "#7C1B01" },
        primary: { main: green[500] },
        secondary: { main: green[500] },
        // primary: { main: colors.primary },
        // secondary: { main: colors.secondary },
    },
});
export default theme;
