import { combineEpics } from "redux-observable";
import { catchError } from "rxjs/operators";
import walletEpics from "./wallet.epics";

export const rootEpic = (action$, store$, dependencies) =>
    combineEpics(walletEpics)(action$, store$, dependencies).pipe(
        catchError((error, source) => {
            console.error(error);
            return source;
        }),
    );
