import React, { Component } from "react";

//Components
import { TextField, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import spinner from "../assets/knobs.png";
import AppBar from "../components/AppBar";
// import { loadReCaptcha, ReCaptcha } from "react-recaptcha-google";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from "../components/spinner/spinner";

//Redux
import * as Actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

//Import & Config
import { LOAD_STATUS, ERROR_TYPES } from "../imports/constants";
import { GOOGLE_RECAPATCHA_API_KEY } from "../imports/API_KEY";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login: false,
            connect: false,
            error: false,
            mnemonic: "",
            verified: false,
            createdError: false,
        };

        this.editState = this.editState.bind(this);
        this.connectWallet = this.connectWallet.bind(this);
        this.createWallet = this.createWallet.bind(this);

        this.props.loadDataRequest();
    }

    editState = (label, value) => {
        value
            ? this.setState({ [label]: value })
            : this.setState({ [label]: !this.state[label] });
    };

    connectWallet = async () => {
        try {
            await this.props.connectWalletRequest({
                mnemonic: this.state.mnemonic,
            });
            this.props.history.push("/dashboard");
        } catch (error) {
            console.log(error);
            this.setState({ error: !this.state.error });
        }
    };

    createWallet = () => {
        if (this.state.verified) {
            this.props.createWalletRequest();
        } else {
            this.editState("createError");
        }
    };

    // componentDidMount() {
    //     loadReCaptcha();
    // }

    render() {
        return (
            <div className="page">
                {this.state.connect || this.state.login ? (
                    <AppBar
                        pageName="ACCESSO/COLLEGAMENTO"
                        back={() => {
                            this.state.login
                                ? this.editState("login")
                                : this.editState("connect");
                        }}
                    />
                ) : (
                    <AppBar pageName="ACCESSO/COLLEGAMENTO" />
                )}

                {/* {this.props.status === LOAD_STATUS.PENDING && (
                    <div className="div-fade">
                        <div className="fade" />
                        <div className="spinner-wrap">
                            <img src={spinner} className="spinner" />
                            <p className="text">Caricamento ...</p>
                        </div>
                    </div>
                )} */}

                <Spinner
                    show={this.props.status === LOAD_STATUS.PENDING}
                    additionalText={true}
                />

                {this.props.status === LOAD_STATUS.SUCCESS ? (
                    <Redirect to="/dashboard" />
                ) : !this.state.login && !this.state.connect ? (
                    <div className="login">
                        <div className="appname">Prenotazioni</div>
                        <div
                            className="button confirmed"
                            onClick={() => {
                                this.editState("login");
                            }}
                        >
                            CREA NUOVO ACCOUNT
                        </div>
                        <div
                            className="button"
                            onClick={() => {
                                this.editState("connect");
                            }}
                        >
                            COLLEGA ACCOUNT
                        </div>
                    </div>
                ) : this.state.login ? (
                    <div className="login create">
                        <p className="title">
                            Per creare il tuo account conferma di essere una
                            persona. Ti serve solo questo, il tutto avverrà in
                            completo anonimato!
                        </p>
                        <ReCAPTCHA
                            size="normal"
                            data-theme="dark"
                            render="explicit"
                            sitekey={GOOGLE_RECAPATCHA_API_KEY}
                            // verifyCallback={() => this.editState("verified")}
                            // expiredCallback={() => this.editState("verified")}
                            onChange={() => this.editState("verified")}
                            onExpired={() => this.editState("verified")}
                        />
                        <div className="button" onClick={this.createWallet}>
                            CREA ACCOUNT
                        </div>
                    </div>
                ) : (
                    <div className="login connect">
                        <p className="title">
                            Per connettere un account già esistente inserisci
                            qui sotto la frase mnemonica.
                        </p>
                        <TextField
                            id="seed"
                            label="Frase mnemonica"
                            color="primary"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth={true}
                            className="textarea"
                            onChange={e => {
                                this.editState("mnemonic", e.target.value);
                            }}
                        />
                        <div className="button" onClick={this.connectWallet}>
                            CONNETTI ACCOUNT
                        </div>
                    </div>
                )}

                <Snackbar
                    open={this.state.createError}
                    autoHideDuration={2000}
                    onClose={() => {
                        this.editState("createError");
                    }}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={() => {
                            this.editState("createError");
                        }}
                        severity="error"
                    >
                        {ERROR_TYPES.NO_CAPTCHA}
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={this.state.error}
                    autoHideDuration={2000}
                    onClose={() => {
                        this.editState("error");
                    }}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={() => {
                            this.editState("error");
                        }}
                        severity="error"
                    >
                        {ERROR_TYPES.WRONG_SEED}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

function mapStateToProps({ general }) {
    return {
        status: general.status,
        error: general.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
