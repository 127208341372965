import React from "react";
// import { Map, GoogleApiWrapper } from "google-maps-react";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAPS_API_KEY } from "../imports/API_KEY";

const mapStyles = {
    width: "100%",
    height: "100%",
};

const defaultProps = {
    center: {
        lat: 45.46,
        lng: 9.12,
    },
    zoom: 11,
};

export const MapContainer = ({ lat, lng }) => (
    <div style={{ height: "100%", width: "100%", borderRadius: "7px" }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
        />
    </div>
);
