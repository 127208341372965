import { applyMiddleware, createStore, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import * as config from "../../imports/config";
import { rootEpic } from "../epics";
import rootReducer from "../reducers/index";
import { ethers } from "ethers";
import { createIpfsEnhancer } from "../../enhancers/createIpfsEnhancer";
import storage from "redux-persist/lib/storage";

const transform = createTransform(
    (inboundState, key) => {
        return {
            ...inboundState,
            mnemonic: inboundState.wallet?.mnemonic?.phrase,
            wallet: null,
        };
    },
    (outboundState, key) => {
        // console.log(outboundState);

        return {
            ...outboundState,
            wallet: outboundState.mnemonic
                ? ethers.Wallet.fromMnemonic(
                      outboundState.mnemonic,
                      "m/44'/60'/0'/0/0",
                  )
                : null,
        };
    },

    { whitelist: ["general"] },
);

const persistConfig = {
    key: "general",
    storage,
    transforms: [transform],
};

const epicMiddleware = createEpicMiddleware({
    dependencies: {
        config,
    },
});

const middlewares = [epicMiddleware];

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares), createIpfsEnhancer()),
);

export let persistor = persistStore(store);

epicMiddleware.run(rootEpic);
