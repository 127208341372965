import React, { Component } from "react";

import moment from "moment";

export default class Reservation extends Component {
    state = {};
    render() {
        return (
            <div className="reservation">
                <div className="logo">{this.props.symbol}</div>
                <div className="data">
                    <p className="shopname">{this.props.shopName}</p>
                    {this.props.begin ? (
                        <p>
                            {moment
                                .unix(this.props.begin)
                                .format("DD/MM/YYYY HH:mm")}{" "}
                            - {moment.unix(this.props.end).format("HH:mm")}
                        </p>
                    ) : (
                        ""
                    )}
                    {this.props.address ? <p>{this.props.address}</p> : ""}
                </div>
            </div>
        );
    }
}
