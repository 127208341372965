import { tutorialConstants } from "../constants/tutorial.constants";

export function nextStep() {
    return { type: tutorialConstants.NEXT_STEP };
}

export function prevStep() {
    return { type: tutorialConstants.PREV_STEP };
}

export function resetTutorial() {
    return { type: tutorialConstants.RESET };
}

export function startTutorial() {
    return { type: tutorialConstants.START };
}

export function stopTutorial() {
    return { type: tutorialConstants.STOP };
}

export function nextStopTutorial() {
    return { type: tutorialConstants.NEXT_STOP };
}

export function finishTutorial() {
    return { type: tutorialConstants.FINISH };
}
