import { walletConstants } from "../constants/wallet.constants";

export function loadDataRequest() {
    return { type: walletConstants.LOAD_DATA_REQUEST };
}

export function loadData(payload) {
    return { type: walletConstants.LOAD_DATA, payload };
}

export function updateDataRequest() {
    return { type: walletConstants.UPDATE_DATA_REQUEST };
}

export function updateData(payload) {
    return { type: walletConstants.UPDATE_DATA, payload };
}

export function createWalletRequest() {
    return { type: walletConstants.CREATE_WALLET_REQUEST };
}

export function createWallet(payload) {
    return { type: walletConstants.CREATE_WALLET, payload };
}

export function connectWallet(payload) {
    return { type: walletConstants.CONNECT_WALLET, payload };
}

export function connectWalletRequest(payload) {
    return { type: walletConstants.CONNECT_WALLET_REQUEST, payload };
}

export function getShopsRequest() {
    return { type: walletConstants.GET_SHOPS_REQUEST };
}

export function getShops(payload) {
    return { type: walletConstants.GET_SHOPS, payload };
}

export function addShopRequest(payload) {
    return { type: walletConstants.ADD_SHOP_REQUEST, payload };
}

export function addShop(payload) {
    return { type: walletConstants.ADD_SHOP, payload };
}

export function getSlotsRequest(payload) {
    return { type: walletConstants.GET_SLOTS_REQUEST, payload };
}

export function getSlots(payload) {
    return { type: walletConstants.GET_SLOTS, payload };
}

export function removeToken(payload) {
    return { type: walletConstants.REMOVE_TOKEN, payload };
}

export function bookSlot(payload) {
    return { type: walletConstants.BOOK_SLOT, payload };
}

export function bookSlotRequest(payload) {
    return { type: walletConstants.BOOK_SLOT_REQUEST, payload };
}

export function deleteReservationRequest(payload) {
    return { type: walletConstants.DELETE_RESERVATION_REQUEST, payload };
}

export function deleteReservation(payload) {
    return { type: walletConstants.DELETE_RESERVATION, payload };
}

export function getReservationsRequest() {
    return { type: walletConstants.GET_RESERVATIONS_REQUEST };
}

export function getReservations(payload) {
    return { type: walletConstants.GET_RESERVATIONS, payload };
}

//OLD

export function resetError() {
    return { type: walletConstants.RESET_ERROR };
}

export function dismiss() {
    return { type: walletConstants.DISMISS };
}

export function resetCreate() {
    return { type: walletConstants.RESET_CREATE };
}

//Use to change status from pending to success when click on slot while downloading from blockcahin
export function success() {
    return { type: walletConstants.SUCCESS };
}

export function clearStorage() {
    return { type: walletConstants.CLEAR_STORAGE };
}

export function loadSuccess(payload) {
    return {
        type: walletConstants.LOAD_SUCCESS,
        payload,
    };
}

export function loadFailure() {
    return {
        type: walletConstants.LOAD_FAILURE,
    };
}

export function loadPending(slots = false) {
    return { type: walletConstants.LOAD_PENDING, payload: slots };
}
