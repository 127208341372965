import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import { getContract } from "../imports/blockchain_utilities";
import { generateGoogleCalendarLink } from "../imports/utils";

import spinner from "../assets/knobs.png";

import AppBar from "../components/AppBar";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import LocationOnIcon from "@material-ui/icons/LocationOn";

// import { compose, withProps } from "recompose";
// import {
//     withScriptjs,
//     withGoogleMap,
//     GoogleMap,
//     Marker,
// } from "react-google-maps";

import { MapContainer } from "../components/GoogleMap";

import Spinner from "../components/spinner/spinner";

import moment from "moment";

import * as Actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import QRCode from "qrcode.react";
import Geocode from "react-geocode";

import { COLORS, LOAD_STATUS, RESERVATION_STATUS } from "../imports/constants";

import { GOOGLE_MAPS_API_KEY, GEOCODING_API_KEY } from "../imports/API_KEY";
import { BCODE_ADDRESS } from "../imports/contract_config";
import CustomButton from "../components/customButton/customButton";

Geocode.setApiKey(GEOCODING_API_KEY);
Geocode.setLanguage("it");
Geocode.setRegion("it");

const getCoordinates = address => {
    return Geocode.fromAddress(address).then(
        response => {
            const { lat, lng } = response.results[0].geometry.location;
            return { lat, lng };
        },
        error => {
            console.log("Error while geocoding address");
            return { lat: 45.46, lng: 9.12 };
        },
    );
};

// const Maps = compose(
//     withProps({
//         googleMapURL: `//maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&language=it`,
//         loadingElement: <div style={{ height: `100%` }} />,
//         containerElement: <div style={{ height: `100%` }} />,
//         mapElement: <div style={{ height: `100%`, borderRadius: "7px" }} />,
//     }),
//     withScriptjs,
//     withGoogleMap,
// )(props => (
//     <GoogleMap
//         defaultZoom={12}
//         defaultCenter={{ lat: props.lat, lng: props.lng }}
//     >
//         {props.isMarkerShown && (
//             <Marker
//                 position={{ lat: props.lat, lng: props.lng }}
//                 onClick={props.onMarkerClick}
//             />
//         )}
//     </GoogleMap>
// ));

class ManageReservation extends Component {
    constructor(props) {
        super(props);
        const reservation = this.props.reservations?.find(res => {
            return this.props.match.params.id === res.tokenId;
        });

        const slot = reservation
            ? reservation
            : this.props.match.params.slot === "tutorial"
            ? {
                  tokenId: "tutorial",
                  begin: moment().unix(),
                  end: moment().unix(),
                  index: 0,
                  shopId: BCODE_ADDRESS,
              }
            : this.props.slots[this.props.match.params.store]?.find(slot => {
                  return this.props.match.params.slot === slot.tokenId;
              });

        this.state = {
            shop: this.props.match.params.store
                ? this.props.shops?.find(
                      shop => shop.id === this.props.match.params.store,
                  )
                : this.props.shops?.find(shop => shop.id === slot.shopId),

            slot: slot,
            manage: !!this.props.match.params.id,
            confirmed: false,
            canceled: false,
            showQRCode: false,
            showConfirmDelete: false,
        };

        // if (!this.state.slot) this.props.history.push("/dashboard");

        this.goBack = this.goBack.bind(this);
        this.goToDashboard = this.goToDashboard.bind(this);
        this.editState = this.editState.bind(this);
    }

    editState = (label, value) => {
        value
            ? this.setState({ [label]: value })
            : this.setState({ [label]: !this.state[label] });
    };

    goBack = () => {
        this.props.resetError();
        this.props.history.goBack();
    };

    goToDashboard = () => {
        this.props.resetError();
        this.props.history.push("/dashboard");
    };

    async componentDidMount() {
        if (!this.props.tutorial.run) {
            const coordinates = await getCoordinates(this.state.shop?.address);
            this.setState({
                shop: { ...this.state.shop, ...coordinates },
                googleLink: generateGoogleCalendarLink({
                    ...this.state.slot,
                    ...this.state.shop,
                }),
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.status !== this.props.status &&
            this.props.status === RESERVATION_STATUS.CONFIRMED
        ) {
            this.setState({ manage: true });
        }
    }

    render() {
        return (
            <div className="page">
                <AppBar
                    pageName="GESTISCI PRENOTAZIONE"
                    back={this.props.history.goBack}
                />

                {!this.state.slot ? <Redirect to={"/dashboard"} /> : ""}

                {/* {this.props.status === LOAD_STATUS.PENDING ? (
                    <div className="div-fade">
                        <div className="fade" />
                        <div className="spinner-wrap">
                            <img src={spinner} className="spinner" />
                            <p className="text">Caricamento ...</p>
                        </div>
                    </div>
                ) : (
                    ""
                )} */}

                <Spinner
                    show={this.props.status === LOAD_STATUS.PENDING}
                    additionalText={true}
                />
                <div
                    className={`div-info ${this.state.manage ? "manage" : ""}`}
                >
                    <div
                        className={`info ${this.state.manage ? "manage" : ""}`}
                    >
                        <p className="title">NEGOZIO</p>
                        <p className="value">{this.state.shop?.name}</p>
                    </div>
                    <div
                        className={`info ${this.state.manage ? "manage" : ""}`}
                    >
                        <p className="title">FASCIA ORARIA</p>
                        <div className="value date">
                            <p>
                                {moment
                                    .unix(this.state.slot.begin)
                                    .format("DD/MM/YYYY")}
                            </p>
                            <p>
                                {moment
                                    .unix(this.state.slot.begin)
                                    .format("HH:mm")}{" "}
                                {" - "}
                                {moment
                                    .unix(this.state.slot.end)
                                    .format("HH:mm")}
                            </p>
                        </div>
                    </div>
                    {this.state.manage ? (
                        <div
                            className={`info manage`}
                            onClick={() => this.editState("showQRCode")}
                        >
                            <p className="title">TOKEN ID</p>
                            <p className="value token">
                                {this.props.match.params.id}
                            </p>
                        </div>
                    ) : (
                        ""
                    )}

                    <div
                        className={`info ${
                            this.state.manage ? "location-manage" : "location"
                        }`}
                    >
                        <div className="location-wrap">
                            <div className="inner">
                                <p className="title">INDIRIZZO</p>
                                <p className="value">
                                    {this.state.shop?.address}
                                </p>
                            </div>
                            <a
                                className="maps-icon"
                                href={`https://www.google.com/maps/?q=${this.state.shop?.lat},${this.state.shop?.lng}`}
                            >
                                <LocationOnIcon
                                    color="secondary"
                                    fontSize="medium"
                                />
                            </a>
                        </div>
                        <div className="maps">
                            {/* <Maps
                                lat={this.state.shop?.lat || 45.46}
                                lng={this.state.shop?.lng || 9.12}
                            /> */}
                            <MapContainer
                                lat={this.state.shop?.lat || 45.46}
                                lng={this.state.shop?.lng || 9.12}
                            />
                        </div>
                    </div>
                </div>
                {this.state.manage ? (
                    this.props.status === RESERVATION_STATUS.CANCELED ? (
                        <div className="btn-div manage">
                            <Link to="/dashboard" className="button back-home">
                                TORNA ALLA DASHBOARD
                            </Link>
                        </div>
                    ) : (
                        <div className="btn-div manage">
                            <CustomButton
                                cancel={true}
                                label={"AGGIUGNI A GOOGLE CALENDAR"}
                                link={this.state.googleLink}
                            />
                            <CustomButton
                                className="button cancel"
                                onClick={() =>
                                    this.editState("showConfirmDelete")
                                }
                                label={"CANCELLA PRENOTAZIONE"}
                            />
                        </div>
                    )
                ) : this.props.status === RESERVATION_STATUS.CONFIRMED ? (
                    <div className="btn-div">
                        <Link to="/dashboard" className="button back-home">
                            TORNA ALLA DASHBOARD
                        </Link>
                    </div>
                ) : (
                    <div className="btn-div">
                        <Link to="/dashboard" className="button cancel">
                            ANNULLA OPERAZIONE
                        </Link>
                        <div
                            className="step-4 button confirm"
                            onClick={() => {
                                this.props.bookSlotRequest({
                                    tokenId: this.state.slot.tokenId,
                                    shopId: this.state.slot.shopId,
                                });
                            }}
                        >
                            CONFERMA PRENOTAZIONE
                        </div>
                    </div>
                )}
                <Snackbar
                    open={this.props.canceled}
                    autoHideDuration={2000}
                    onClose={this.props.dismiss}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={this.props.dismiss}
                        severity="error"
                    >
                        Prenotazione cancellata!
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={this.props.confirmed}
                    autoHideDuration={2000}
                    onClose={this.props.dismiss}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={this.props.dismiss}
                        severity="success"
                    >
                        Prenotazione effettuata!
                    </Alert>
                </Snackbar>

                {/* Dialog that show the token ID QR Code */}
                <Dialog
                    open={this.state.showQRCode}
                    onClose={() => this.editState("showQRCode")}
                >
                    <QRCode
                        style={{
                            width: "300px",
                            height: "300px",
                            padding: "5px",
                        }}
                        value={this.state.slot.tokenId}
                    />
                </Dialog>

                {/* Dialog for confirm the deletion of reservation */}
                <Dialog
                    open={this.state.showConfirmDelete}
                    onClose={() => this.editState("showConfirmDelete")}
                >
                    <DialogTitle>Conferma cancellazione</DialogTitle>
                    <DialogContent>
                        Sei sicuro di voler eliminare la presentazione?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.editState("showConfirmDelete")}
                            style={{
                                fontWeight: "bold",
                                backgroundColor: COLORS.tertiary,
                                color: "white",
                            }}
                        >
                            ANNULLA
                        </Button>
                        <Button
                            onClick={() => {
                                this.editState("showConfirmDelete");
                                this.props.deleteReservationRequest({
                                    tokenId: this.state.slot.tokenId,
                                    shopId: this.state.slot.shopId,
                                    old: false,
                                });
                            }}
                            style={{
                                fontWeight: "bold",
                                backgroundColor: COLORS.secondary,
                                color: "#ffffff",
                            }}
                        >
                            CONFERMA
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Dialog to deal with reservations error */}
                <Dialog open={this.props.status === RESERVATION_STATUS.ERROR}>
                    <DialogTitle id="alert-dialog-title">
                        {this.state.manage
                            ? "Cancellazione non riuscita!"
                            : "Prenotazione non riuscita!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.manage
                                ? "Non è stato possibile cancellare la prenotazione."
                                : "Non è stato possibile effettuare la prenotazione, prova un orario differente o controlla i tuoi token."}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.goBack}
                            style={{
                                fontWeight: "bold",
                                backgroundColor: COLORS.tertiary,
                                color: "white",
                            }}
                        >
                            {this.state.manage
                                ? "TORNA ALLA DASHBOARD"
                                : "CAMBIA ORARIO"}
                        </Button>

                        <Button
                            open={this.state.manage}
                            onClick={() => {
                                this.state.manage
                                    ? this.props.resetError()
                                    : this.goToDashboard();
                            }}
                            style={{
                                fontWeight: "bold",
                                backgroundColor: COLORS.secondary,
                                color: "#ffffff",
                            }}
                        >
                            {this.state.manage ? "RIMANI" : "DASHBOARD"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
function mapStateToProps({ general, tutorial }) {
    return {
        wallet: general.wallet,
        canceled: general.canceled === RESERVATION_STATUS.CANCELED,
        confirmed: general.confirmed === RESERVATION_STATUS.CONFIRMED,
        status: general.status,
        alertStatus: general.alert,
        reservations: general.reservations,
        slots: general.slots,
        shops: [...general.shops, ...general.untrustedShops],
        tutorial,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageReservation);
