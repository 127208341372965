import React, { Component } from "react";

import * as Actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import Calendar from "react-calendar-material";

import styleColors from "../styles/_colors.scss";

import TimeButton from "../components/TimeButton";

import _ from "lodash";

class Test extends Component {
  constructor(props) {
    super(props);

    this.state = {
      delay: 100,
      result: "No result",
      showQRCode: true,
      slots: [],
    };

    this.editState = this.editState.bind(this);
  }

  editState = (label, value) => {
    value
      ? this.setState({ [label]: value })
      : this.setState({ [label]: !this.state[label] });
  };

  componentDidMount() {
    this.setState({
      slots: Object.values(_.groupBy(this.props.slots, "begin")),
    });
  }

  render() {
    console.log(this.state.slots);
    return (
      <div className="page">
        <Calendar
          accentColor={styleColors.secondary}
          orientation={"flex-col"}
          showHeader={false}
          lang="ita"
          style={{ flex: "2" }}
          eventsBool={[true, false, true, false]}
        />
      </div>
    );
  }
}

function mapStateToProps({ general }) {
  // console.log("SLOTS ==>", general);
  return {
    slots: general.slots,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Test);
