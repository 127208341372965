import { walletConstants } from "../redux/constants/wallet.constants";
import ipfs from "ipfs";
import { testnet } from "../imports/contract_config";
import { ethers } from "ethers";

export var ipfsNode = null;
export var provider = null;

export const createIpfsEnhancer =
    () =>
    createStore =>
    (...args) => {
        const store = createStore(...args);
        provider = new ethers.providers.JsonRpcProvider(testnet.addr);
        ipfs.create().then(node => {
            ipfsNode = node;

            node.bootstrap
                .add(
                    "/ip4/213.136.90.96/tcp/4001/ipfs/QmeMTajwQLn7JruhJXZshdEdYNPTjRtEJXvDJUa7FRDbLU",
                )
                .then(() =>
                    store.dispatch({
                        type: walletConstants.CREATE_NODE,
                        payload: true,
                    }),
                );
        });

        return store;
    };
