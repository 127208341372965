export const walletConstants = {
    LOAD_DATA_REQUEST: "LOAD_DATA_REQUEST",
    LOAD_DATA: "LOAD_DATA",
    UPDATE_DATA_REQUEST: "UPDATE_DATA_REQUEST",
    UPDATE_DATA: "UPDATE_DATA",
    CREATE_WALLET_REQUEST: "CREATE_WALLET_REQUEST",
    CREATE_WALLET: "CREATE_WALLET",
    GET_RESERVATIONS: "GET_RESERVATIONS",
    GET_RESERVATIONS_REQUEST: "GET_RESERVATIONS_REQUEST",
    CONNECT_WALLET: "CONNECT_WALLET",
    CONNECT_WALLET_REQUEST: "CONNECT_WALLET_REQUEST",
    GET_SHOPS: "GET_SHOPS",
    GET_SHOPS_REQUEST: "GET_SHOPS_REQUEST",
    ADD_SHOP: "ADD_SHOP",
    ADD_SHOP_REQUEST: "ADD_SHOP_REQUEST",
    GET_SLOTS: "GET_SLOTS",
    GET_SLOTS_REQUEST: "GET_SLOTS_REQUEST",
    REMOVE_TOKEN: "REMOVE_TOKEN",
    BOOK_SLOT: "BOOK_SLOT",
    BOOK_SLOT_REQUEST: "BOOK_SLOT_REQUEST",
    DELETE_RESERVATION: "DELETE_RESERVATION",
    DELETE_RESERVATION_REQUEST: "DELETE_RESERVATION_REQUEST",

    LOAD_SUCCESS: "LOAD_SUCCESS",
    LOAD_FAILURE: "LOAD_FAILURE",
    LOAD_PENDING: "LOAD_PENDING",
    SUCCESS: "SUCCESS",
    GET_RESERVATIONS: "GET_RESERVATIONS",

    GET_USER_BALANCE: "GET_USER_BALANCE",

    ERROR: "ERROR",
    RESET_ERROR: "RESET_ERROR",
    RESET_CREATE: "RESET_CREATE",
    DISMISS: "DISMISS",
    CREATE_NODE: "CREATE_NODE",
    CLEAR_STORAGE: "CLEAR_STORAGE",
};
