import React, { Component } from "react";
import moment from "moment";

export default class TimeButton extends Component {
    state = {};

    render() {
        return (
            <div
                key={this.props.tokenId}
                className={`time-button ${
                    moment.unix(this.props.begin).isBefore(moment())
                        ? "disabled"
                        : "available"
                }`}
            >
                {/* <p className="total">{this.props.total}</p> */}
                <div>{moment.unix(this.props.begin).format("HH:mm")}</div>
                <div>{moment.unix(this.props.end).format("HH:mm")}</div>
            </div>
        );
    }
}
