import React from "react";

import { ADDITIONAL_TEXT } from "../../imports/constants";
import spinner from "../../assets/knobs.png";

import "./spinner.scss";

export default function Spinner(props) {
    const { show, additionalText } = props;

    function getRandom(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    return (
        <>
            {show && (
                <div className="div-fade">
                    <div className="fade" />
                    <div className="spinner-wrap">
                        <img src={spinner} className="spinner" />
                        <p className="text">Caricamento ...</p>
                        {additionalText && (
                            <p className="text">
                                {
                                    ADDITIONAL_TEXT[
                                        getRandom(0, ADDITIONAL_TEXT.length - 1)
                                    ]
                                }
                            </p>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
