export const LOAD_STATUS = {
    ERROR: -1,
    SUCCESS: 0,
    PENDING: 2,
};

export const RESERVATION_STATUS = {
    ERROR: -1,
    SUCCESS: 0,
    CANCELED: 1,
    PENDING: 2,
    CONFIRMED: 3,
};

export const RESET_ERROR = 0;

export const SHOP_STATUS = {
    ERROR: -1,
    ADDED: 1,
};

export const ERROR_TYPES = {
    ALREADY_EXISTING_SHOP: "Negozio già presente!",
    WRONG_INFO: "Informazioni errate!",
    WRONG_QR_CODE: "QR Code errato!",
    NO_CAMERA: "Impossibile aprire camera!",
    NO_CAPTCHA: "Verifica non effettuata!",
    WRONG_SEED: "Frase non corretta!",
};

export const SUCCESS_TYPES = {
    SHOP_ADDED: "Negozio aggiunto con successo",
    COPY_SEED: "Frase mnemonica copiata!",
};

export const getShopInfo_addr = "https://ethexplorer.knobs.it/getShopInfo";
// "http://localhost:9300/getShopInfo";

export const ADDITIONAL_TEXT = [
    "...stiamo allineando i pianeti.",
    //   "...momento, momento, momento!",
    "...potrebbe volerci un po' di tempo.",
    "...stiamo aggiustando l'inclinazione dell'asse terrestre.",
    "...per le cose più belle ci vuole sempre un po' di pazienza.",
];

export const COLORS = {
    primary: "#1d1d1b",
    primaryLight: "rgba(29, 29, 27, 0.28)",
    secondary: "#b8d04e",
    secondaryLight: "#f6f5ec",
    tertiary: "#4b4b4b",
    tertiaryLight: "#969696",
};
