import { ethers, BigNumber } from "ethers";
import {
    contract_info,
    registry_contract_info,
    testnet,
} from "./contract_config";

import { orderBy } from "lodash";

export const provider = new ethers.providers.JsonRpcProvider(testnet.addr);
export const StoreInterface = new ethers.utils.Interface(contract_info.abi);

export function getContract(wallet, contractAddr, abi = contract_info.abi) {
    // const provider = new ethers.providers.JsonRpcProvider(testnet.addr);
    return new ethers.Contract(contractAddr, abi, wallet.connect(provider));
}

//The regex allow to take the integer part and one digits after dot
export function getFormattedBalance(address) {
    return provider.getBalance(address).then(result => {
        return ethers.utils.formatEther(result).match(/^[0-9]*.[0-9]/);
    });
}

export function getBalance(address) {
    return provider.getBalance(address).then(result => result);
}

export async function getRegistry(wallet) {
    let registry = await getContract(
        wallet,
        registry_contract_info.addr,
        registry_contract_info.abi,
    );

    return registry.getRegistry().then(res => res);
}

export async function extractOwnedToken(contract, owner) {
    provider.resetEventsBlock(0);
    //   let transfers = await provider.getLogs(contract.filters.Transfer(owner));

    // let startBlock = 9845330;
    let startBlock = 19758174;
    let endBlock = (await provider.getBlock("latest")).number;

    const chunks = new Array(Math.floor((endBlock - startBlock) / 99999))
        .fill(99999)
        .concat((endBlock - startBlock) % 99999);

    let toMe = [];
    let fromMe = [];

    for (const [i, ch] of chunks.entries()) {
        endBlock = startBlock + ch;

        //Take all transaction from everyone to me
        toMe = [
            ...toMe,
            ...(await contract.queryFilter(
                contract.filters.Transfer(null, owner),
                startBlock,
                endBlock,
            )),
        ];

        //Take all transaction from me to everyone
        fromMe = [
            ...fromMe,
            ...(await contract.queryFilter(
                contract.filters.Transfer(owner),
                startBlock,
                endBlock,
            )),
        ];

        startBlock = startBlock + ch + 1;
    }

    //Merge fromMe e toMe and order by blockNumber and logIndex
    let transfers = orderBy([...toMe, ...fromMe], ["blockNumber", "logIndex"]);

    // Initialize ownedTokens with 0 tokens for the store owner
    let ownedTokens = {
        [owner]: [],
    };

    let ownedTokensIndex = {};

    function addToken(to, tokenId) {
        // Create the array if it doesn't exist
        if (ownedTokens[to] === undefined) {
            ownedTokens[to] = [];
        }

        // Store the token index
        ownedTokensIndex[tokenId] = ownedTokens[to].length;

        // Add the token to the array
        ownedTokens[to].push(tokenId);
    }

    function removeToken(from, tokenId) {
        if (ownedTokens[from] !== undefined) {
            let lastTokenIndex = ownedTokens[from].length - 1;
            let tokenIndex = ownedTokensIndex[tokenId];

            if (tokenIndex != lastTokenIndex) {
                // When the token to delete is the last token, the swap operation is unnecessary
                let lastTokenId = ownedTokens[from][lastTokenIndex];

                ownedTokens[from][tokenIndex] = lastTokenId; // Move the last token to the slot of the to-delete token
                ownedTokensIndex[lastTokenId] = tokenIndex; // Update the moved token's index
            }

            // This also deletes the contents at the last position of the array
            ownedTokens[from].pop();
        } else {
            // console.log("nothing to remove");
        }
    }

    transfers.forEach(event => {
        let tokenId = event.args.tokenId.toString();

        // console.log("Token id", tokenId);
        removeToken(event.args.from, tokenId);
        addToken(event.args.to, tokenId);
    });

    return ownedTokens;
}

export async function getSlotsFallback(contract, prevSlots, shop) {
    let owner = await contract.owner();
    let ownedTokens = [];
    try {
        ownedTokens = await extractOwnedToken(contract, owner);
    } catch (err) {
        console.log(err);
    }
    if (ownedTokens[owner] && ownedTokens[owner].length) {
        let tokenIdHex = ownedTokens[owner].map(tk =>
            BigNumber.from(tk).toHexString(),
        );

        let parsedTokens = [];
        let parsedLoggedTokens = [];

        try {
            // let startBlock = 9845330;
            let startBlock = shop.blockCreation || 19759174;
            let endBlock = (await provider.getBlock("latest")).number;

            const chunks = new Array(
                Math.floor((endBlock - startBlock) / 99999),
            )
                .fill(99999)
                .concat((endBlock - startBlock) % 99999);

            for (const [i, ch] of chunks.entries()) {
                endBlock = startBlock + ch;

                // console.log(
                //     "PARSED TOKEN =+>",
                //     await provider.getLogs({
                //         ...contract.filters.SlotCreated(tokenIdHex),
                //         fromBlock: startBlock,
                //         toBlock: endBlock,
                //     }),
                //     contract.filters,
                // );

                parsedTokens = [
                    ...parsedTokens,
                    ...(await provider.getLogs({
                        ...contract.filters.SlotCreated(tokenIdHex),
                        fromBlock: startBlock,
                        toBlock: endBlock,
                    })),
                ];

                startBlock = startBlock + ch + 1;
            }

            // parsedTokens = await provider.getLogs({
            //     ...contract.filters.SlotCreated(tokenIdHex),
            //     fromBlock: 9845330,
            //     toBlock: "latest",
            // });
            parsedLoggedTokens = parsedTokens.map(token =>
                StoreInterface.parseLog(token),
            );
        } catch (err) {
            console.log(err);
        }

        console.log("Loading new slots!");

        let slots = prevSlots[shop.id] ? [...prevSlots[shop.id]] : [];

        slots = parsedLoggedTokens.map(tk => ({
            tokenId: tk.args.tokenId.toString(),
            begin: tk.args.begin.toNumber(),
            end: tk.args.end.toNumber(),
            index: tk.args.index.toString(),
            shopId: shop.id,
        }));

        return {
            slots: slots.sort((a, b) => {
                return a.begin - b.begin;
            }),
            shopId: shop.id,
        };
    } else {
        return { status: "UPDATED" };
    }
}
