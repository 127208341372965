import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import { LOAD_STATUS, SUCCESS_TYPES } from "../imports/constants";

import styleColors from "../styles/_colors.scss";

//Components
import spinner from "../assets/knobs.png";
import BottomBar from "../components/BottomBar";
import AppBar from "../components/AppBar";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    InputAdornment,
    Snackbar,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Collapse,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
// import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddIcon from "@material-ui/icons/Add";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
    ExpandLess,
    ExpandMore,
    ExitToApp,
    CheckCircleOutline,
} from "@material-ui/icons";
import Reservation from "../components/Reservation";
import Spinner from "../components/spinner/spinner";

//Redux
import * as Actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reservations: [],
            showSettings: false,
            created: this.props.created,
            alert: false,
            today: moment(),
            delay: 100,
            result: "No result",
            open: false,
        };

        this.editState = this.editState.bind(this);
        this.copyMnemonic = this.copyMnemonic.bind(this);
    }

    editState = label => {
        this.setState({ [label]: !this.state[label] });
    };

    copyMnemonic = () => {
        var copyText = document.getElementById("mnemonic");

        copyText.select();
        copyText.setSelectionRange(0, 99999);

        document.execCommand("copy");

        this.editState("alert");
    };

    componentDidMount() {
        //If there's a wallet I call updateData to get registry. reservations and balance
        if (this.props.wallet) {
            this.props.dismiss();
            this.props.updateDataRequest();
        }
        if (this.props.tutorial.firstTime) {
            this.props.getShopsRequest();
        }
    }

    render() {
        return (
            <div className="page">
                <AppBar pageName="DASHBOARD" />
                {/* {this.props.status === LOAD_STATUS.PENDING ? (
                    <div className="div-fade">
                        <div className="fade" />
                        <div className="spinner-wrap">
                            <img src={spinner} className="spinner" />
                            <p className="text">Caricamento ...</p>
                        </div>
                    </div>
                ) : (
                    ""
                )} */}

                <Spinner
                    show={this.props.status === LOAD_STATUS.PENDING}
                    additionalText={true}
                />

                <Dialog open={this.state.showSettings}>
                    <DialogTitle>Impostazioni</DialogTitle>
                    <DialogContent className="settings">
                        <List component="nav" className="expansion">
                            <ListItem
                                button
                                onClick={() => {
                                    this.editState("open");
                                }}
                            >
                                <ListItemText primary="Frase mnemonica" />

                                {this.state.open ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItem>

                            <Collapse in={this.state.open} unmountOnExit>
                                <List>
                                    <ListItem>
                                        <TextField
                                            id="mnemonic"
                                            defaultValue={this.props.mnemonic}
                                            fullWidth={true}
                                            multiline
                                            maxRows={4}
                                            autoFocus
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FileCopyIcon
                                                            style={{
                                                                fill: styleColors.teritiary,
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                                onClick: this.copyMnemonic,
                                            }}
                                        />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button style={{ width: "90%" }}>
                                <ListItemText primary="Esci dall'app" />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={this.props.clearStorage}
                                    >
                                        <ExitToApp style={{ fill: "red" }} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.editState("open");
                                this.editState("showSettings");
                            }}
                            color="primary"
                        >
                            CHIUDI
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={
                        this.props.tutorial.firstTime &&
                        !this.props.tutorial.run
                    }
                >
                    <DialogTitle>Benvenuto!</DialogTitle>
                    <DialogContent className="tutorial">
                        Hai bisogno di un tutorial?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.props.finishTutorial();
                            }}
                            color="primary"
                        >
                            RIFIUTA
                        </Button>
                        <Button
                            onClick={() => {
                                this.props.resetTutorial();
                                this.props.startTutorial();
                            }}
                            color="secondary"
                            variant="contained"
                        >
                            INIZIA
                        </Button>
                    </DialogActions>
                </Dialog>

                {!this.props.reservations.length &&
                this.props.tutorial.stepIndex !== 4 ? (
                    <div className="div-reservations">
                        <div className="no-reservations">
                            <p className="text">
                                Non hai nessuna prenotazione!
                            </p>
                            <br />
                            <div className="text">
                                Premi sul
                                <div className="button_in_text">
                                    <AddIcon
                                        className="settin-icon"
                                        fontSize="medium"
                                        style={{ color: "white" }}
                                    />
                                </div>
                                per aggiungerne una!
                            </div>
                        </div>

                        {/* {!this.props.tutorial.run && (
                            <Button
                                onClick={() => {
                                    this.props.resetTutorial();
                                    this.props.startTutorial();
                                }}
                                style={{
                                    fontWeight: "bold",
                                    backgroundColor: styleColors.secondary,
                                    color: "white",
                                    marginBottom: 30,
                                }}
                            >
                                Inizia tutorial
                            </Button>
                        )} */}
                    </div>
                ) : (
                    <div className="div-reservations">
                        {this.props.tutorial.run &&
                        this.props.tutorial.stepIndex === 4 ? (
                            <div className="label-div ">
                                <div className="label">OGGI:</div>
                                <div className="wrapper">
                                    <Reservation
                                        begin={new Date().getTime()}
                                        end={new Date().getTime()}
                                        key={"tutorial"}
                                        symbol={"BCD"}
                                        shopName={"Bcode"}
                                        booking={false}
                                    />
                                </div>
                            </div>
                        ) : this.props.todayReservations.length ? (
                            <div className="label-div ">
                                <div className="label">OGGI:</div>
                                {this.props.todayReservations.map(res => (
                                    <Link
                                        key={res.tokenId}
                                        to={`/reservation/${res.tokenId}`}
                                        className="wrapper"
                                    >
                                        <Reservation
                                            begin={res.begin}
                                            end={res.end}
                                            key={res.tokenId}
                                            symbol={res.symbol}
                                            shopName={res.shopName}
                                            booking={false}
                                        />
                                    </Link>
                                ))}
                            </div>
                        ) : (
                            <div className="label-div ">
                                <div className="label">OGGI:</div>
                                <div className="text-div">
                                    Nessuna prenotazione per oggi!
                                </div>
                            </div>
                        )}
                        {this.props.futureReservations.length ? (
                            <div className="label-div ">
                                <div className="label">PROSSIMI GIORNI:</div>
                                {this.props.futureReservations.map(res => (
                                    <Link
                                        key={res.tokenId}
                                        to={`/reservation/${res.tokenId}`}
                                        className="wrapper"
                                    >
                                        <Reservation
                                            begin={res.begin}
                                            end={res.end}
                                            key={res.tokenId}
                                            symbol={res.symbol}
                                            shopName={res.shopName}
                                            booking={false}
                                        />
                                    </Link>
                                ))}
                            </div>
                        ) : (
                            <div className="label-div">
                                <div className="label">PROSSIMI GIORNI:</div>
                                <div className="text-div">
                                    Nessuna prenotazione i prossimi giorni!
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <BottomBar
                    userBalance={this.props.userBalance}
                    dashboard={true}
                    toggleSettings={() => this.editState("showSettings")}
                />

                <Dialog open={this.props.created}>
                    <DialogContent>
                        <div className="dialog">
                            <CheckCircleOutline
                                style={{
                                    fill: styleColors.secondary,
                                    width: "60px",
                                    height: "60px",
                                }}
                            />
                            <div className="text">
                                {this.state.showDialog
                                    ? "Salva la frase mnemonica qui sotto e conservala in un luogo sicuro!"
                                    : "Il tuo account è stato creato con successo! Salva la frase mnemonica qui sotto e conservala in un luogo sicuro!"}
                            </div>
                        </div>
                        <TextField
                            id="mnemonic"
                            className="textarea"
                            label="Frase Mnemonica"
                            defaultValue={this.props.mnemonic}
                            variant="outlined"
                            fullWidth={true}
                            multiline
                            maxRows={4}
                            autoFocus
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <FileCopyIcon
                                            style={{
                                                fill: styleColors.secondary,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                onClick: this.copyMnemonic,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.props.resetCreate}
                            color="primary"
                        >
                            CHIUDI
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={this.state.alert}
                    autoHideDuration={2000}
                    onClose={() => {
                        this.editState("alert");
                    }}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={() => {
                            this.editState("alert");
                        }}
                        severity="success"
                    >
                        {SUCCESS_TYPES.COPY_SEED}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

function mapStateToProps({ general, tutorial }) {
    return {
        wallet: general.wallet,
        created: general.created,
        mnemonic: general.mnemonic,
        reservations: _.orderBy(general.reservations, ["begin"], ["asc"]),
        todayReservations: _.orderBy(
            general.reservations?.filter(res => {
                return moment.unix(res.begin).isSame(moment(), "day");
            }),
            ["begin"],
            ["asc"],
        ),
        futureReservations: _.orderBy(
            general.reservations?.filter(res => {
                return moment.unix(res.begin).isAfter(moment().endOf("day"));
            }),
            ["begin"],
            ["asc"],
        ),
        userBalance: general.userBalance,
        status: general.status,
        isAuth: general.isAuth,
        tutorial,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
