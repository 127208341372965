import { combineEpics, ofType } from "redux-observable";
import { from, of, pipe } from "rxjs";
import { mergeMap, catchError } from "rxjs/operators";

import { walletConstants } from "../constants/wallet.constants";
import { ipfsNode } from "../../enhancers/createIpfsEnhancer";

import bs58 from "bs58";

import axios from "axios";

import moment from "moment";
import { difference, merge, result, uniq } from "lodash";

import { ethers, BigNumber } from "ethers";

import {
    StoreInterface,
    getContract,
    getFormattedBalance,
    getBalance,
    getRegistry,
    getSlotsFallback,
    provider,
} from "../../imports/blockchain_utilities";
import { contract_info, address_burn } from "../../imports/contract_config";
import { getShopInfo_addr } from "../../imports/constants";
import { getCachedData, delay } from "../../imports/utils";

import {
    createWallet,
    getSlots,
    loadFailure,
    loadSuccess,
    loadData,
    updateData,
    getReservationsRequest,
    getReservations,
    connectWallet,
    getShops,
    addShop,
    loadPending,
    deleteReservation,
    bookSlot,
} from "../actions/index";

import { gql, request } from "graphql-request";

const loadDataEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.LOAD_DATA_REQUEST),
        mergeMap(() => {
            const mnemonic = state$.value.general.mnemonic;

            if (mnemonic) {
                let wallet = ethers.Wallet.fromMnemonic(
                    mnemonic,
                    "m/44'/60'/0'/0/0",
                );

                return [
                    loadData({
                        wallet,
                    }),
                    loadSuccess(),
                ];
            } else {
                return of(loadFailure());
            }
        }),
    );
};

const updateDataEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.UPDATE_DATA_REQUEST),
        mergeMap(() => {
            const fun = async () => {
                try {
                    let balance = await getFormattedBalance(
                        state$.value.general.wallet.address,
                    );
                    let registry = await getRegistry(
                        state$.value.general.wallet,
                    );

                    return { balance, registry };
                } catch (err) {
                    return { err };
                }
            };

            return from(fun()).pipe(
                mergeMap(result => {
                    if (result.err) {
                        return of(loadFailure());
                    } else {
                        return of(updateData(result), getReservationsRequest());
                    }
                }),
            );
        }),
    );
};

const createWalletEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.CREATE_WALLET_REQUEST),
        mergeMap(() => {
            let wallet = ethers.Wallet.createRandom();
            const fun = async wallet => {
                try {
                    fetch("https://api.faucet.matic.network/transferTokens", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            address: wallet.address,
                            network: "mumbai",
                            token: "maticToken",
                        }),
                    });
                    while (await (await getBalance(wallet.address)).isZero()) {
                        console.log("Waiting token from faucet ...");
                    }
                    return true;
                } catch {
                    return false;
                }
            };
            return from(fun()).pipe(
                mergeMap(result => {
                    if (result.err) {
                        return of(loadFailure());
                    } else {
                        return of(createWallet({ wallet }), loadSuccess());
                    }
                }),
            );
        }),
    );
};

const connectWalletEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.CONNECT_WALLET_REQUEST),
        mergeMap(({ payload: { mnemonic } }) => {
            if (mnemonic) {
                return of(
                    connectWallet({
                        mnemonic,
                        wallet: ethers.Wallet.fromMnemonic(
                            mnemonic,
                            "m/44'/60'/0'/0/0",
                        ),
                    }),
                    loadSuccess(),
                );
            } else {
                of(loadFailure());
            }
        }),
    );
};

const getShopsEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.GET_SHOPS_REQUEST),
        mergeMap(() => {
            const fun = async registry => {
                let shops = [];

                if (registry.length) {
                    for (const addr of registry) {
                        let contract = getContract(state.wallet, addr);

                        let shopInfo = await axios
                            .get(`${getShopInfo_addr}/${addr}`)
                            .then(({ data }) => data)
                            .catch(error => console.log("error", error));

                        shops.push({
                            id: addr,
                            name: await contract.name(),
                            symbol: await contract.symbol(),
                            address: shopInfo?.data?.address,
                            phone: shopInfo?.data?.phone,
                            img: shopInfo?.data?.img,
                        });
                    }
                }
                return shops;
            };

            let state = state$.value.general;

            let registry = uniq([
                ...state.registry[0],
                // ...state.shops.map(shop => shop.id),
                // ...state.untrustedShops.map(shop => shop.id),
            ]);

            return from(fun(registry)).pipe(
                mergeMap(shops => {
                    if (shops.length) {
                        return of(getShops({ shops }), loadSuccess());
                    } else {
                        return of(loadFailure());
                    }
                }),
            );
        }),
    );
};

//Epic to add an untrusted shop to the list of shops
const addShopEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.ADD_SHOP_REQUEST),
        mergeMap(({ payload: { id } }) => {
            let state = state$.value.general;
            let untrustedShops = [...state.untrustedShops];

            let contract = getContract(state.wallet, id);

            const fun = async () => {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ shopId: id }),
                };
                try {
                    const shopInfo = (
                        await axios.post(getShopInfo_addr, {
                            shopId: id,
                        })
                    ).data;

                    console.log(shopInfo);
                    untrustedShops.push({
                        id: id,
                        name: await contract.name(),
                        symbol: await contract.symbol(),
                        address: shopInfo?.data?.address,
                        phone: shopInfo?.data?.phone,
                        img: shopInfo?.data?.img,
                        untrusted: true,
                    });
                    return untrustedShops;
                } catch (e) {
                    console.log(e);
                    return { err: "Error" };
                }
            };

            return from(fun()).pipe(
                mergeMap(result => {
                    console.log(result);
                    if (result.err) {
                        return of(loadFailure());
                    } else {
                        return of(addShop(result), loadSuccess());
                    }
                }),
            );
        }),
    );
};

//Epic to take a list of tokenIds from IPFS and then request corresponding data from the Blockchain
const getSlotsEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.GET_SLOTS_REQUEST),
        mergeMap(({ payload: { shopId } }) => {
            let state = state$.value.general;

            let shop = [...state.shops, ...state.untrustedShops].find(
                el => el.id === shopId,
            );

            let currentTokenIds = state.slots[shopId]
                ? state.slots[shopId].map(slot => slot.tokenId)
                : [];

            // let contract = null;

            // while (!provider) {
            let contract = getContract(state.wallet, shopId);
            // }

            const fun = async () => {
                let owner = await contract.owner();
                // let node = ipfsNode;

                try {
                    // let ipfsProof = await contract.getTokensOfOwnerIpfs(owner);

                    let slots = (
                        await request(
                            "https://api.studio.thegraph.com/query/11447/prenotazionitest/v0.0.10",
                            gql`
                          {
                            slotEntities(where: {store: "${shopId}", owner: "${owner}"}, orderBy: begin, orderDirection: asc) {
                              id
                              begin
                              end
                              index
                              owner
                              store
                            }
                          }
                        `,
                        )
                    ).slotEntities.map(slot => ({
                        ...slot,
                        tokenId: slot.id,
                        shopId: slot.store,
                    }));

                    console.log(
                        `Found ${slots.length} slots for ${shopId} store `,
                    );

                    return {
                        slots,
                        shopId,
                    };

                    // let cachedData = await Promise.race([
                    //     getCachedData(node, ipfsProof),
                    //     delay(5000),
                    // ]);

                    // if (cachedData) {
                    //     console.log("Fetching slots from IPFS...", cachedData);
                    //     let tokenIds = difference(cachedData, currentTokenIds);
                    //     // console.log(tokenIds);
                    //     let result = [];

                    //     //REST API REQUEST
                    //     if (tokenIds.length) {
                    //         let tokenIdHex = tokenIds.map(tk =>
                    //             BigNumber.from(tk).toHexString(),
                    //         );

                    //         let parsedTokens = [];
                    //         try {
                    //             // let startBlock = 9845330;
                    //             let startBlock = shop.blockCreation || 19758174;
                    //             let endBlock = (
                    //                 await provider.getBlock("latest")
                    //             ).number;

                    //             const chunks = new Array(
                    //                 Math.floor((endBlock - startBlock) / 99999),
                    //             )
                    //                 .fill(99999)
                    //                 .concat((endBlock - startBlock) % 99999);

                    //             for (const [i, ch] of chunks.entries()) {
                    //                 endBlock = startBlock + ch;

                    //                 parsedTokens = [
                    //                     ...parsedTokens,
                    //                     ...(await provider.getLogs({
                    //                         ...contract.filters.SlotCreated(
                    //                             tokenIdHex,
                    //                         ),
                    //                         fromBlock: startBlock,
                    //                         toBlock: endBlock,
                    //                     })),
                    //                 ];

                    //                 startBlock = startBlock + ch + 1;
                    //             }
                    //             // parsedTokens = await provider.getLogs({
                    //             //     ...contract.filters.SlotCreated(tokenIdHex),
                    //             //     fromBlock:
                    //             //         (
                    //             //             await provider.getBlock("latest")
                    //             //         ).blockNumber - 998,
                    //             //     toBlock: "latest",
                    //             // });
                    //         } catch (err) {
                    //             console.log("GET LOGS ERROR =>", err);
                    //         }

                    //         let parsedLoggedTokens = parsedTokens.map(token =>
                    //             StoreInterface.parseLog(token),
                    //         );

                    //         console.log("Loading new slots!", parsedTokens);

                    //         let slots = state.slots[shopId]
                    //             ? state.slots[shopId].filter(tk =>
                    //                   cachedData.includes(tk.tokenId),
                    //               )
                    //             : [];

                    //         slots = [
                    //             ...slots,
                    //             ...parsedLoggedTokens.map(tk => ({
                    //                 tokenId: tk.args.tokenId.toString(),
                    //                 begin: tk.args.begin.toNumber(),
                    //                 end: tk.args.end.toNumber(),
                    //                 index: tk.args.index.toString(),
                    //                 shopId: shopId,
                    //             })),
                    //         ];
                    //         return {
                    //             slots: slots.sort((a, b) => {
                    //                 return a.begin - b.begin;
                    //             }),
                    //             shopId: shopId,
                    //         };
                    //     } else {
                    //         console.log("Nothing to update!");
                    //         return { status: "UPDATED" };
                    //     }
                    // } else {
                    //     console.log("Fetching slots from BLOCKCHAIN...");
                    //     return getSlotsFallback(
                    //         contract,
                    //         state$.value.general.slots,
                    //         shop,
                    //     );
                    // }
                } catch (err) {
                    console.log(err);
                    return { error: true };
                    // return getSlotsFallback(
                    //     contract,
                    //     state$.value.general.slots,
                    //     shop,
                    // );
                }
            };

            return from(fun()).pipe(
                mergeMap(result => {
                    if (result.error) {
                        return of(loadFailure());
                    } else {
                        return of(getSlots(result));
                    }
                }),
            );
        }),
    );
};

// const removeTokenEpic =(action$, state$) => {
//     return action$.pipe(
//         ofType(walletConstants.REMOVE_TOKEN),
//         mergeMap(({payload:{tokenId, shopId}})=>{return of()})
//     )
// }

const bookSlotEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.BOOK_SLOT_REQUEST),
        mergeMap(({ payload: { tokenId, shopId } }) => {
            const state = state$.value.general;

            const fun = async () => {
                try {
                    let contract = getContract(state.wallet, shopId);
                    let tx = await contract.reserve(tokenId, {
                        gasLimit: 300000,
                        gasPrice: 5000000000,
                    });
                    console.log("TRANSACTION ==>", tx);

                    let receipt = await tx.wait();

                    // console.log("AFTER REQUEST", state.slots, shopId);
                    return {
                        slots: state.slots[receipt.to].filter(slot => {
                            return slot.tokenId !== tokenId;
                        }),
                        shopId: shopId,
                        err: null,
                    };
                } catch (err) {
                    console.log(err);
                    return { err };
                }
            };

            return from(fun()).pipe(
                mergeMap(result => {
                    if (result.err) {
                        return of(loadFailure());
                    } else {
                        return of(bookSlot(result), loadSuccess());
                    }
                }),
            );
        }),
    );
};

//TODO: think a better way to handle no pending and succes in while
//deleting in background
const deleteReservationEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.DELETE_RESERVATION_REQUEST),
        mergeMap(({ payload: { tokenId, shopId, old } }) => {
            const state = state$.value.general;

            let contract = getContract(state.wallet, shopId);

            const fun = async () => {
                const owner = await contract.owner();
                try {
                    let tx = await contract.transferFrom(
                        state.wallet.address,
                        old ? address_burn : owner,
                        tokenId,
                    );

                    await tx.wait();

                    return {
                        reservations: state.reservations.filter(res => {
                            return res.tokenId !== tokenId;
                        }),
                        old: old,
                    };
                } catch (err) {
                    console.log(err);
                    return { err };
                }
            };

            return from(fun()).pipe(result => {
                if (result.err) {
                    return of(loadFailure());
                } else {
                    return of(deleteReservation(result));
                }
            });
        }),
    );
};

const getReservationsEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.GET_RESERVATIONS_REQUEST),
        mergeMap(() => {
            let state = state$.value.general;

            const fun = async () => {
                let reservations = [];

                let result = (
                    await request(
                        "https://api.studio.thegraph.com/query/11447/prenotazionitest/v0.0.10",
                        gql`
                          {
                            slotEntities(where: {owner: "${state.wallet.address}"}, orderBy: begin, orderDirection: asc) {
                              id
                              begin
                              end
                              index
                              owner
                              store
                            }
                          }
                        `,
                    )
                ).slotEntities;

                for (const slot of result) {
                    const contract = getContract(
                        state.wallet,
                        slot.store,
                        contract_info.abi,
                    );
                    reservations.push({
                        ...slot,
                        tokenId: slot.id,
                        shopId: slot.store,
                        shopName: await contract.name(),
                        symbol: await contract.symbol(),
                    });
                }

                return reservations;
            };
            return from(fun()).pipe(
                mergeMap(reservations => {
                    if (reservations.length) {
                        return of(getReservations(reservations), loadSuccess());
                    } else {
                        return of(loadSuccess());
                    }
                }),
            );
        }),
    );
};

export default combineEpics(
    loadDataEpic,
    updateDataEpic,
    connectWalletEpic,
    getShopsEpic,
    addShopEpic,
    getSlotsEpic,
    bookSlotEpic,
    deleteReservationEpic,
    createWalletEpic,
    getReservationsEpic,
);
