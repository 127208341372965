import React, { Component } from "react";
import { Link } from "react-router-dom";

// import SettingsIcon from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import SettingsIcon from "@material-ui/icons/Settings";

export default class BottomBar extends Component {
    state = {};

    render() {
        return (
            <div
                className={`bottombar ${this.props.dashboard ? "" : "booking"}`}
            >
                {this.props.toggleSettings ? (
                    <div
                        className="button-setting"
                        onClick={this.props.toggleSettings}
                    >
                        <SettingsIcon
                            className="settin-icon"
                            fontSize="large"
                            style={{ color: "white" }}
                        />
                        {/* <VpnKeyIcon
              className="settin-icon"
              fontSize="large"
              style={{ color: "white" }}
            /> */}
                    </div>
                ) : (
                    ""
                )}

                <p className="label">
                    {this.props.dashboard
                        ? `${this.props.userBalance} Token`
                        : this.props.label}
                </p>

                {this.props.dashboard ? (
                    <Link to="/store" className="button-add">
                        <AddIcon fontSize="large" style={{ color: "white" }} />
                    </Link>
                ) : (
                    <div
                        className="button-add"
                        onClick={this.props.toggleAddShop}
                    >
                        <AddIcon fontSize="medium" style={{ color: "white" }} />
                    </div>
                )}
            </div>
        );
    }
}
