import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export default function AppBar(props) {
    return (
        <div className="appbar">
            {props.back ? (
                <ArrowBackIosIcon
                    fontSize="medium"
                    style={{ position: "absolute", left: "15px", top: "15px" }}
                    onClick={props.back}
                />
            ) : (
                ""
            )}
            {props.pageName}
        </div>
    );
}
