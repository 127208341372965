//TEST PERSONALI
// export const GOOGLE_RECAPATCHA_API_KEY =
//     "6LdHGvkUAAAAANOmFQAmtAF2J1nl_nzHCobV1z6Y";

// export const GOOGLE_MAPS_API_KEY = "AIzaSyBHIsHEV3xXlaid8E9MbrWUJd_KvVhHGK4";

// export const GEOCODING_API_KEY = "AIzaSyDSS_FuUotj3tPxR-6N73g6gvWAc7NdsZ4";

//OFFICIAL BCODE
export const GOOGLE_RECAPATCHA_API_KEY =
    "6Lc2lUUaAAAAAAyQt-ydMXvcT9Dr81KmSaRDCZtv";

export const GOOGLE_MAPS_API_KEY = "AIzaSyAGqDuBmvE6ejkfzEu9sMyPvDd1HfZyo58";

export const GEOCODING_API_KEY = "AIzaSyDL3CXCvw83W385cBy7h2kf4xIVd-2a974";
