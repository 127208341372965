import React, { Component } from "react";
import { Link } from "react-router-dom";

import { getContract } from "../imports/blockchain_utilities";

import spinner from "../assets/knobs.png";

import styleColors from "../styles/_colors.scss";

import BottomBar from "../components/BottomBar";
import AppBar from "../components/AppBar";
import Store from "../components/Store";
import {
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    InputAdornment,
    Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";
import CropFreeIcon from "@material-ui/icons/CropFree";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Spinner from "../components/spinner/spinner";

import QrReader from "react-qr-reader";

import * as Actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
    LOAD_STATUS,
    ERROR_TYPES,
    SUCCESS_TYPES,
    SHOP_STATUS,
    COLORS,
} from "../imports/constants";

class Booking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: "",
            newShopName: "",
            newShopAddr: "",
            showAddShop: false,
            showCamera: false,
            qrcodeError: false,
        };

        this.editState = this.editState.bind(this);
        this.toggleAddShop = this.toggleAddShop.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleScan = this.handleScan.bind(this);
    }

    handleScan = async data => {
        let qrData = data?.split("/");
        console.log(qrData);
        if (data) {
            if (this.state.showCamera === "goToSlot") {
                //Check first element and then redirect to slot page
                try {
                    await getContract(this.props.wallet, qrData[0]);
                    if (
                        this.props.slots[qrData[0]].filter(slot => {
                            return slot.tokenId === qrData[1];
                        }).length
                    ) {
                        this.props.history.push(`/store/${data}`);
                    } else {
                        this.editState(
                            "qrcodeError",
                            ERROR_TYPES.WRONG_QR_CODE,
                        );
                    }
                } catch (error) {
                    console.log("ERRORE ==>", error);
                    this.editState("qrcodeError", ERROR_TYPES.WRONG_QR_CODE);
                }
            } else {
                //Address check is done in action function
                try {
                    if (
                        !this.props.shops.filter(shop => {
                            return shop.id === qrData[0];
                        }).length
                    ) {
                        qrData.length === 1
                            ? await this.props.addShopRequest({
                                  id: qrData[0],
                              })
                            : this.editState(
                                  "qrcodeError",
                                  ERROR_TYPES.WRONG_QR_CODE,
                              );
                    } else {
                        this.editState(
                            "qrcodeError",
                            ERROR_TYPES.ALREADY_EXISTING_SHOP,
                        );
                    }
                } catch (error) {
                    this.editState("qrcodeError", ERROR_TYPES.WRONG_QR_CODE);
                }
            }
            this.editState("showCamera");
        }
    };

    handleError = err => {
        this.editState("showCamera");
        this.editState("qrcodeError", ERROR_TYPES.NO_CAMERA);
    };

    editState = (label, value) => {
        value
            ? this.setState({ [label]: value })
            : this.setState({ [label]: !this.state[label] });
    };

    toggleAddShop = () => {
        //The default value of error is false when it's true it's a string
        if (!this.props.error)
            this.setState({ showAddShop: !this.state.showAddShop });
    };

    addShop = async () => {
        if (
            !this.props.shops.filter(shop => {
                return shop.id === this.state.newShopAddr;
            }).length &&
            this.state.newShopAddr.length
        ) {
            await this.props.addShopRequest({
                id: this.state.newShopAddr,
            });
        } else {
            this.editState("qrcodeError", ERROR_TYPES.ALREADY_EXISTING_SHOP);
        }
        this.toggleAddShop();
    };

    componentDidMount() {
        this.props.getShopsRequest();
    }

    render() {
        return (
            <div className="page">
                <AppBar pageName="BOOKING" back={this.props.history.goBack} />

                {/* {this.props.status === LOAD_STATUS.PENDING ? (
                    <div className="div-fade">
                        <div className="fade" />
                        <div className="spinner-wrap">
                            <img src={spinner} className="spinner" />
                            <p className="text">Caricamento ...</p>
                        </div>
                    </div>
                ) : (
                    ""
                )} */}

                <Spinner
                    show={this.props.status === LOAD_STATUS.PENDING}
                    additionalText={true}
                />

                <div className="page">
                    <div className="bar">
                        <div className="search">
                            <TextField
                                className="textarea"
                                id="search"
                                label="Cerca"
                                variant="outlined"
                                fullWidth={true}
                                color="primary"
                                onChange={e =>
                                    this.editState(
                                        "search",
                                        e.target.value.toLowerCase(),
                                    )
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div>
                            <CropFreeIcon
                                fontSize="large"
                                onClick={() => {
                                    this.editState("showCamera", "goToSlot");
                                }}
                            />
                        </div>
                    </div>

                    <div className="div-reservations">
                        {(this.state.search.length
                            ? this.props.shops.filter(shop => {
                                  return shop.name
                                      .toLowerCase()
                                      .includes(this.state.search);
                              })
                            : this.props.shops
                        ).map(shop => (
                            <Link
                                key={shop.id}
                                to={`/store/${shop.id}`}
                                className="store-wrap"
                            >
                                <Store shop={shop} />
                            </Link>
                        ))}
                    </div>

                    <BottomBar
                        label="Non trovi il tuo negoziante? Aggiungilo!"
                        dashboard={false}
                        toggleAddShop={this.toggleAddShop}
                    />
                </div>

                <Dialog
                    open={this.state.showAddShop}
                    onClose={this.toggleAddShop}
                    style={{ zIndex: "9" }}
                >
                    <DialogTitle id="form-dialog-title" color="secondary">
                        Inserisci negozio
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Inserisci l'indirizzo fornito dal tuo negoziante.
                        </DialogContentText>
                        <TextField
                            className="textarea"
                            variant="outlined"
                            margin="dense"
                            id="addr"
                            label="Indirizzo"
                            type="text"
                            fullWidth={true}
                            onChange={e =>
                                this.editState("newShopAddr", e.target.value)
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.toggleAddShop();
                                this.editState("showCamera", "shop");
                                this.editState("showAddShop");
                            }}
                            style={{
                                fontWeight: "bold",
                                backgroundColor: COLORS.tertiary,
                                color: "white",
                            }}
                        >
                            QR Code
                        </Button>
                        <Button
                            onClick={this.addShop}
                            style={{
                                fontWeight: "bold",
                                backgroundColor: COLORS.secondary,
                                color: "#ffffff",
                            }}
                        >
                            Aggiungi
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={this.props.error || this.state.qrcodeError}
                    autoHideDuration={2000}
                    onClose={() => {
                        this.props.error
                            ? this.props.resetError()
                            : this.editState("qrcodeError", false);
                    }}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={() => {
                            this.props.error
                                ? this.props.resetError()
                                : this.editState("qrcodeError", false);
                        }}
                        severity="error"
                    >
                        {this.props.error || this.state.qrcodeError}
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={this.props.added}
                    autoHideDuration={2000}
                    onClose={this.props.dismiss}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={this.props.dismiss}
                        severity="success"
                    >
                        {SUCCESS_TYPES.SHOP_ADDED}
                    </Alert>
                </Snackbar>

                {this.state.showCamera ? (
                    <div className="div-fade">
                        <div className="fade" />
                        <div className="qrcode">
                            <QrReader
                                delay={this.state.delay}
                                style={{
                                    height: 240,
                                    width: "80vw",
                                }}
                                onError={this.handleError}
                                onScan={this.handleScan}
                            />
                        </div>
                        <HighlightOffIcon
                            fontSize="large"
                            style={{
                                fill: "red",
                                marginTop: "150px",
                                width: "55px",
                                height: "55px",
                                zIndex: "11",
                            }}
                            onClick={() => {
                                this.editState("showCamera");
                            }}
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

function mapStateToProps({ general }) {
    return {
        wallet: general.wallet,
        shops: [...general.shops, ...general.untrustedShops],
        error: general.error,
        status: general.status,
        added: general.confirmed === SHOP_STATUS.ADDED,
        mnemonic: general.mnemonic,
        slots: general.slots,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
